<template>
  <el-card class="main-card">
    <template #header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">快递业务</el-breadcrumb-item>
        <el-breadcrumb-item>快递月结单</el-breadcrumb-item>
      </el-breadcrumb>
    </template>
    <el-form label-width="90px" :model="querys" ref="querys" class="search-form">
      <el-row>
        <el-form-item label="关键词" prop="keyword">
          <el-col>
            <el-input v-model="querys.keyword" @keyup.enter="fetchs" prefix-icon="Search"></el-input>
          </el-col>
        </el-form-item>

        <el-form-item label="" label-width="20px">
          <el-button type="primary" icon="search" @click="fetchs()">搜索</el-button>
        </el-form-item>
      </el-row>
    </el-form>

    <div class="toolbar">
      <el-button type="primary" icon="plus" @click="redirect()">月结清单导入</el-button>
    </div>

    <static-table class="table" :pager="pager" :columns="querys.columns" :currHandler="currHandler" :sortHandler="sortHandler" :pageHandler="pageHandler" :sizeHandler="sizeHandler">
      <template v-slot:billDate="scope">
        <i class="el-icon-time" v-show="scope.row.billDate"></i>
        <span style="margin-left: 10px">{{
          (scope.row.billDate || "").split(" ")[0]
        }}</span>
      </template>
      <template v-slot:toolbar="scope">
        <el-button @click="edit(scope.row)" type="primary" link size="small">[查看]</el-button>
        <el-button @click="dele(scope.row)" type="primary" link size="small">[删除]</el-button>
      </template>
    </static-table>
  </el-card>
</template>
<script>
import StaticDialog from "@/components/static-dialog.vue";
import StaticTable from "@/components/static-table.vue";
import { fetchBillMasterList, delBillMaster } from "@/api/mailscale";
export default {
  name: "MailBillList",
  components: { StaticDialog, StaticTable },
  data() {
    return {
      apiUrl: "",
      querys: {
        keyword: "",
        filters: [],
        columns: [
          { type: "index", label: "序号" },
          {
            prop: "districtCode",
            label: "地区编号",
            width: "100",
            sortable: true,
            searchable: true,
          },
          {
            prop: "parterName",
            label: "客户名称",
            width: "150",
            sortable: true,
            searchable: true,
          },
          {
            prop: "contactName",
            label: "联系人",
            width: "120",
            sortable: true,
            searchable: true,
          },
          {
            prop: "contactPhone",
            label: "联系方式",
            width: "110",
            sortable: true,
            searchable: true,
          },
          {
            type: "template",
            prop: "billDate",
            label: "账单日期",
            width: "130",
            sortable: true,
          },

          {
            prop: "billTotal",
            label: "数量",
            width: "110",
            sortable: true,
          },
          {
            prop: "billFee",
            label: "总费用",
            width: "110",
            sortable: true,
          },
          {
            prop: "billDis",
            label: "总折扣",
            width: "110",
            sortable: true,
          },
          {
            prop: "billPay",
            label: "应付",
            width: "110",
            sortable: true,
          },

          { prop: "operater",label: "操作人",searchable: true,},
          { prop: "remark",label: "备注",searchable: true,},
          { prop: "createDate", label: "创建时间", width: "150", sortable: true, },
          { prop: "createUserName", label: "创建人", sortable: true, searchable: true, width: "100", },
          { type: "toolbar", label: "操作", width: "130" },
        ],
      },
      pager: {
        sidx: "billDate", //默认的排序字段
        sord: "descending",
      },
      currRow: null,
    };
  },
  mounted() {
    this.fetchs();
    this.apiUrl = process.env.VUE_APP_BASEURL;
  },
  methods: {
    fetchs() {
      this.querys.filters = [];
      let { data, records, total, ...search } = {
        ...this.pager,
        ...this.querys,
      };

      fetchBillMasterList(search).then((res) => {
        this.pager = { ...res.data };
      });
    },
    edit(row) {
      this.$router.push("detail?id=" + row.id);
    },
    dele(row) {
      let that = this;
      this.$confirm("此操作将删除本页选择数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delBillMaster(row.id).then((res) => {
            if (res.code === 200) {
              that.$message({
                type: "success",
                message: "删除成功",
              });
              that.fetchs();
            }
          });
        })
        .catch(() => {
          that.$message({
            type: "info",
            message: "删除失败",
          });
        });
    },
    redirect() {
      this.$router.push("detail");
    },
    currHandler(row) {
      this.currRow = row;
    },
    pageHandler(page) {
      this.pager.page = page;
      this.fetchs();
    },
    sizeHandler(rows) {
      this.pager.rows = rows;
      this.fetchs();
    },
    sortHandler(order) {
      this.pager.sidx = order.prop;
      this.pager.sord = order.order;
      this.fetchs();
    },
  },
};
</script>
<style lang="less">
</style>>

